import React from 'react';
import {
    HeadingLayout,
    Heading,
    HeadingSpan,
    SpecificsSection,
    SpecificsLayout,
    SpecificsCard,
    SpecificsCardHeading,
    SpecificsCardPara,
    SpecificsRow,
    SpecificsItems,
} from './specifics.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

const Specifics = () => {  
    const JSONData  = useStaticQuery(graphql`
        query{
            realestatePage3Json {
                Specifics {
                    Heading
                    HeadingSpan
                    DelayConstant
                    SpecificsLayout{
                        SpecificsCardHeading
                        SpecificsCardPara
                        SpecificsItems{
                            SpecificsItemName
                        }
                    }
                }
            }
        }
    `);
    console.log(JSONData);
    const SpecificsData = JSONData.realestatePage3Json.Specifics;
    return(
        <SpecificsSection id="specificsSection">
            <Container>
                <SpecificsRow>
                    <Col md="12" lg="12">
                        <SpecificsLayout>
                            <Row>
                                <Col md={12}>
                                    <HeadingLayout>
                                        <Heading>
                                        {SpecificsData.Heading}
                                        <HeadingSpan>{SpecificsData.HeadingSpan}</HeadingSpan>
                                        </Heading>
                                    </HeadingLayout>
                                </Col>
                            </Row>
                            <Row>
                            { 
                                SpecificsData.SpecificsLayout.map((specificsItem,idx) => {
                                return <Col lg="4" md="6" sm="12" key={idx}>
                                        <Fade delay={(idx+1)*SpecificsData.DelayConstant}>
                                            <SpecificsCard position={idx+1}>
                                                <SpecificsCardHeading>{specificsItem.SpecificsCardHeading}</SpecificsCardHeading>
                                                <SpecificsCardPara>{specificsItem.SpecificsCardPara}</SpecificsCardPara>
                                                {specificsItem.SpecificsItems &&
                                                    <SpecificsItems>
                                                        <ol>
                                                        { 
                                                            specificsItem.SpecificsItems.map((item,idxItem) => {
                                                            return <li key={idxItem}>
                                                                    {item.SpecificsItemName}
                                                                </li>
                                                            })
                                                        }
                                                        </ol>
                                                    </SpecificsItems>
                                                }
                                            </SpecificsCard>
                                        </Fade>
                                    </Col>
                                })
                            }
                            </Row>
                        </SpecificsLayout>
                    </Col>
                </SpecificsRow>
            </Container>
        </SpecificsSection>
)
}
export default Specifics;