import React from 'react';
import {AboutSection,AboutWrapper,CustomRow,ImageHolder,TextLayout,Heading,HeadingSpan,Description,
    FeaturesLayout,FeaturesTextLayout,FeaturesImg,FeaturesHeading,FeaturesDesc
} from './about.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                realestatePage3Json{
                    About{
                        HeadingSpan
                        Heading
                        Description1
                        Description2
                        Img{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                        }
                        FeaturesImg1
                        FeaturesHeading1
                        FeaturesDesc1
                        FeaturesImg2
                        FeaturesHeading2
                        FeaturesDesc2
                    }
                }
            }
    `);
    const AboutData = JSONData.realestatePage3Json.About;
    return (
        <AboutSection id="aboutSection">
            
            <Container>
                <AboutWrapper>
                    <CustomRow>
                        <Col lg={6}>
                            <TextLayout>
                                <Heading>
                                    <HeadingSpan>
                                        {AboutData.HeadingSpan}
                                    </HeadingSpan>
                                    {AboutData.Heading}
                                </Heading>
                                <Description>
                                {AboutData.Description1} 
                                </Description>
                                <Description>
                                {AboutData.Description2} 
                                </Description>
                            
                                <Row>
                                    <Col md={6} xs={6}>
                                        <FeaturesLayout>
                                            <FeaturesImg 
                                                src={AboutData.FeaturesImg1} 
                                                alt=""
                                            />
                                            <FeaturesTextLayout>
                                                <FeaturesHeading>
                                                {AboutData.FeaturesHeading1} 
                                                </FeaturesHeading>
                                                <FeaturesDesc>
                                                {AboutData.FeaturesDesc1}
                                                </FeaturesDesc>
                                            </FeaturesTextLayout>
                                        </FeaturesLayout>
                                    </Col>
                                    <Col md={6} xs={6}>
                                        <FeaturesLayout>
                                            <FeaturesImg 
                                                src={AboutData.FeaturesImg2} 
                                                alt=""
                                            />
                                            <FeaturesTextLayout>
                                                <FeaturesHeading>
                                                {AboutData.FeaturesHeading2} 
                                                </FeaturesHeading>
                                                <FeaturesDesc>
                                                {AboutData.FeaturesDesc2}
                                                </FeaturesDesc>
                                            </FeaturesTextLayout>
                                        </FeaturesLayout>
                                    </Col>
                                </Row>
                            </TextLayout>
                        </Col>
                        <Col lg={6}>
                            <ImageHolder>
                                <GatsImg 
                                    fluid={AboutData.Img.childImageSharp.fluid} 
                                    alt=""
                                    className="aboutImg"
                                />
                            </ImageHolder>
                        </Col>
                    </CustomRow>
                    
                </AboutWrapper>
            </Container>
            
        </AboutSection>
    );
}

export default About;