import React,{Component} from 'react';
import {GallerySection,Heading,HeadingSpan,CustomRow,CustomCol,
    GalleryImageHolder,GalleryImageHolderBig
} from './gallery.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

class Gallery extends Component {
    
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }

    render() {
        return (
            <GallerySection id="gallerySection">
                <Container>
                    <Heading>
                        {this.props.GalleryData.Heading}
                        <HeadingSpan>{this.props.GalleryData.HeadingSpan}</HeadingSpan>
                    </Heading>

                    <CustomRow>

                        <CustomCol md={4}>
                            <GalleryImageHolderBig onClick={this.openDialogBox.bind(this,this.props.GalleryData,0)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[0].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryImageHolderBig>
                        </CustomCol>

                        <CustomCol md={8}>
                            
                            <CustomRow>
                                <CustomCol md={6}>
                                    <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,1)}>
                                        <GatsImg 
                                            fluid={this.props.GalleryData.Slider[1].GallerySliderImg.childImageSharp.fluid} 
                                            className="GallerySliderImg"
                                            alt=""
                                        />
                                    </GalleryImageHolder>
                                </CustomCol>
                                <CustomCol md={6}>
                                    <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,2)}>
                                        <GatsImg 
                                            fluid={this.props.GalleryData.Slider[2].GallerySliderImg.childImageSharp.fluid} 
                                            className="GallerySliderImg"
                                            alt=""
                                        />
                                    </GalleryImageHolder>
                                </CustomCol>
                            </CustomRow>

                            <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,3)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[3].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryImageHolder>
                            
                        </CustomCol>
                    </CustomRow>

                    <CustomRow>
                        <CustomCol md={8}>
                            <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,4)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[4].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryImageHolder>
                        </CustomCol>
                        <CustomCol md={4}>
                            <GalleryImageHolder onClick={this.openDialogBox.bind(this,this.props.GalleryData,5)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[5].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryImageHolder>
                        </CustomCol>

                    </CustomRow>
                </Container>
            </GallerySection>
        );
    }
}

export default props => (
<StaticQuery
    query={graphql`
        query {
            realestatePage3Json{
                Gallery{
                    Heading
                    HeadingSpan
                    Slider{
                        GallerySliderImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
    render={(data) => (
        <Gallery 
        GalleryData={data.realestatePage3Json.Gallery}
        {...props}
        />
    )}
/>
)
