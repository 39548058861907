
import axios from "axios";
const baseUri = "https://wogd-api-rrksp3eeya-uc.a.run.app/api";

export const addRecord = (information, entityName) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return new Promise(async (resolve, reject) => {
        await axios.post(`${baseUri}/${entityName}`, information, {
            headers: headers
        })
            .then((res) => {
                console.log("done",res)
                resolve(res.data)
            }).catch((error) => {
                reject(error)
            })
    })
}

